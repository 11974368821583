import React from "react";
import { NavLink as Link } from "react-router-dom";
import {
    Collapse,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Row,
    Col,
    DropdownItem,
} from "reactstrap";
import "./header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import lngOptions from "../../i18n/lngOptions";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            isNavDropdownOpen: false,
            isLngSelectDropdownOpen: false,
        };
        this.toggleNavDropdown = this.toggleNavDropdown.bind(this);
        this.goToDataMap = this.goToDataMap.bind(this);
        this.goToSnapshots = this.goToSnapshots.bind(this);
        this.goToIdeaFeed = this.goToIdeaFeed.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
        this.toggleLngSelectDropdown = this.toggleLngSelectDropdown.bind(this);
    }

    toggleNav() {
        const { isNavOpen } = this.state;

        // close menu if mobile
        if (window.innerWidth <= 992) {
            this.setState({
                isNavOpen: !isNavOpen,
            });
        }
    }

    goToDataMap(lang) {
        window.open(`https://alight.kujakuja.com/${lang}/datamap`, "_blank");
        this.toggleNavDropdown();
    }

    goToSnapshots(lang) {
        window.open(`https://alight.kujakuja.com/${lang}/snapshots`, "_blank");
        this.toggleNavDropdown();
    }

    goToIdeaFeed(lang) {
        window.open(`https://alight.kujakuja.com/${lang}/ideafeed`, "_blank");
        this.toggleNavDropdown();
    }

    toggleNavDropdown() {
        const { isNavDropdownOpen } = this.state;

        this.setState({
            isNavDropdownOpen: !isNavDropdownOpen,
        });
    }

    toggleLngSelectDropdown() {
        const { isLngSelectDropdownOpen } = this.state;

        this.setState({
            isLngSelectDropdownOpen: !isLngSelectDropdownOpen,
        });
    }

    render() {
        const { changeLanguage, selectedLanguage } = this.props;
        const { isNavOpen, isLngSelectDropdownOpen, isNavDropdownOpen } = this.state;
        const selectedLanguageOption = lngOptions.find((lng) => {
            return lng.value === selectedLanguage;
        });
        let selectedLanguageName;
        if (selectedLanguageOption) {
            selectedLanguageName = selectedLanguageOption.name;
        }
        const currentLanguage = i18n.language;

        return (
            <div>
                <Navbar className="header" expand="lg">
                    <NavLink className="customer-logo-nav-link" to="/" tag={Link} onClick={this.toggleNav}>
                        <img alt={i18n.t("header.clientLogoAlt")} className="customer-logo" src={require("../../img/global_logo_dark.svg")} />
                    </NavLink>
                    <NavbarToggler onClick={this.toggleNav} />
                    <Collapse isOpen={isNavOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <Dropdown isOpen={isNavDropdownOpen} toggle={this.toggleNavDropdown}>
                                <DropdownToggle
                                    tag="span"
                                    onClick={this.toggleNavDropdown}
                                    data-toggle="dropdown"
                                    aria-expanded={isNavDropdownOpen}
                                >
                                    {i18n.t("header.ourProducts")}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div className="arrow-up" />
                                    <Row onClick={() => this.goToDataMap(currentLanguage)}>
                                        <Col className="col-sm-10">
                                            <h5>{i18n.t("header.dataMaPProductsTitle")}</h5>
                                            <div>{i18n.t("header.dataMapProductsDescription")}</div>
                                        </Col>
                                        <Col className="col-sm-2 right">
                                            <FontAwesomeIcon icon="arrow-right" />
                                        </Col>
                                    </Row>
                                    <Row onClick={() => this.goToIdeaFeed(currentLanguage)}>
                                        <Col className="col-sm-10">
                                            <h5>{i18n.t("header.ideaFeedProductsTitle")}</h5>
                                            <div>{i18n.t("header.ideaFeedProductsDescription")}</div>
                                        </Col>
                                        <Col className="col-sm-2 right">
                                            <FontAwesomeIcon icon="arrow-right" />
                                        </Col>
                                    </Row>
                                    <Row onClick={() => this.goToSnapshots(currentLanguage)}>
                                        <Col className="col-sm-10">
                                            <h5>{i18n.t("header.snapshotProductsTitle")}</h5>
                                            <div>{i18n.t("header.snapshotProductsDescription")}</div>
                                        </Col>
                                        <Col className="col-sm-2 right">
                                            <FontAwesomeIcon icon="arrow-right" />
                                        </Col>
                                    </Row>
                                </DropdownMenu>
                            </Dropdown>

                            <NavItem>
                                <NavLink to="/about" tag={Link} activeClassName="navbar_active" onClick={this.toggleNav}>
                                    {i18n.t("header.aboutUs")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/work-with-us" tag={Link} activeClassName="navbar_active" onClick={this.toggleNav}>
                                    {i18n.t("header.workWithUs")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/contact" tag={Link} activeClassName="navbar_active" onClick={this.toggleNav}>
                                    {i18n.t("header.contact")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink to="/partners" tag={Link} activeClassName="navbar_active" onClick={this.toggleNav}>
                                    {i18n.t("header.partnerLogin")}
                                </NavLink>
                            </NavItem>
                            <Dropdown isOpen={isLngSelectDropdownOpen} toggle={this.toggleLngSelectDropdown}>
                                <DropdownToggle
                                    caret
                                    tag="span"
                                    onClick={this.toggleLngSelectDropdown}
                                    data-toggle="dropdown"
                                    aria-expanded={isLngSelectDropdownOpen}
                                >
                                    {selectedLanguageName}
                                </DropdownToggle>
                                <DropdownMenu className="lng-dropdown-menu">
                                    <div className="arrow-up lng-arrow-up" />
                                    {lngOptions.map((lng) => (
                                        <DropdownItem
                                            key={lng.value}
                                            onClick={changeLanguage}
                                            value={lng.value}
                                            active={selectedLanguage === lng.value}
                                        >
                                            {lng.name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}
