import React from "react";
import { Col, Container, Row } from "reactstrap";
import { IntercomAPI } from "react-intercom";
import i18n from "i18next";
import "./contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterActionCard from "./FooterActionCard";

export default class Contact extends React.Component {
    componentDidMount() {
        document.title = `${i18n.t("contact.pageTitle")}`;
    }

    render() {
        return (
            <div className="contact">

                <div className="title-background-parent">
                    <div className="title-background-shape" />
                </div>

                <Container className="title-parent">
                    <Row>
                        <Col className="col-sm-12">
                            <h3 className="title">{i18n.t("contact.getInTouch")}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-12">
                            <p className="sub-title">
                                {i18n.t("contact.contactSubTitle")}
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container>
                    <Row className="cards-row">
                        <Col className="col-sm-12 col-md-6 action-cards-parent">
                            <FooterActionCard
                                title={i18n.t("contact.businessEnquiries")}
                                description={i18n.t("contact.businessEnquiriesDescription")}
                                footerLink={(
                                    <button
                                        type="button"
                                        className="cta right-arrow"
                                        onClick={() => {
                                            IntercomAPI("showNewMessage", i18n.t("contact.businessEnquiriesIntercomMessage"));
                                        }}
                                    >
                                        {i18n.t("contact.letChat")}
                                        {" "}
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </button>
                                )}
                            />
                        </Col>
                        <Col className="col-sm-12 col-md-6 action-cards-parent">
                            <FooterActionCard
                                title={i18n.t("contact.pressEnquiries")}
                                description={i18n.t("contact.pressEnquiriesDescription")}
                                footerLink={(
                                    <button
                                        type="button"
                                        className="cta right-arrow"
                                        onClick={() => {
                                            IntercomAPI("showNewMessage", i18n.t("contact.pressEnquiriesIntercomMessage"));
                                        }}
                                    >
                                        {i18n.t("contact.getInTouch")}
                                        {" "}
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </button>
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="cards-row">
                        <Col className="col-sm-12 col-md-6 action-cards-parent">
                            <FooterActionCard
                                title={i18n.t("contact.careers")}
                                description={i18n.t("contact.careersDescription")}
                                footerLink={(
                                    <button
                                        type="button"
                                        className="cta right-arrow"
                                        onClick={() => {
                                            IntercomAPI("showNewMessage", i18n.t("contact.careersIntercomMessage"));
                                        }}
                                    >
                                        {i18n.t("contact.pingUs")}
                                        {" "}
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </button>
                                )}
                            />
                        </Col>
                        <Col className="col-sm-12 col-md-6 action-cards-parent">
                            <FooterActionCard
                                title={i18n.t("contact.generalEnquiries")}
                                description={i18n.t("contact.generalEnquiriesDescription")}
                                footerLink={(
                                    <button
                                        type="button"
                                        className="cta right-arrow"
                                        onClick={() => {
                                            IntercomAPI("showNewMessage", i18n.t("contact.generalEnquiriesIntercomMessage"));
                                        }}
                                    >
                                        {i18n.t("contact.messageUs")}
                                        {" "}
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </button>
                                )}
                            />
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}
