import React from "react";
import {
    Col, Container, Row,
} from "reactstrap";
import "./about.scss";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import i18n from "i18next";

import firebase from "firebase/app";
import "firebase/firestore";

import ActionCard from "./ActionCard";
import LearnMore from "../common/LearnMore";
import AppConfig from "../../AppConfig";

am4core.useTheme(am4themes_animated);

export default class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            globalTotal: 0,
        };
    }

    componentDidMount() {
        document.title = `${i18n.t("about.pageTitle")}`;
        this.instantiateMap();

        try {
            if (!firebase.apps.length) {
                firebase.initializeApp(AppConfig.FIREBASE_CONFIG);
            }

            this.fireStore = firebase.firestore();

            this.fireStore.collection("survey_responses").doc("totals")
                .onSnapshot((doc) => {
                    const documentData = doc.data();
                    this.setState({
                        globalTotal: documentData ? documentData.global : 1000000,
                    });
                });
        }
        catch (e) {
            this.setState({
                globalTotal: 1000000,
            });
        }
    }

    instantiateMap = () => {
        // Create AM Charts map instance - https://www.amcharts.com/docs/v4/chart-types/map/
        const chart = am4core.create("kujakuja-map", am4maps.MapChart);

        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Set map projection
        chart.projection = new am4maps.projections.Miller();

        // bg color, not draggable or zoom-able
        chart.background.fill = am4core.color("#F5F5F5");
        chart.background.fillOpacity = 1;
        chart.seriesContainer.draggable = false;
        chart.seriesContainer.resizable = false;
        chart.maxZoomLevel = 1;

        // required for showing countries
        const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;

        const polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.strokeOpacity = 0; // hide borders
        polygonTemplate.fill = am4core.color("#d6d3d5");

        // Remove Antarctica
        polygonSeries.exclude = ["AQ"];

        // hide tooltips of non-affiliated countries
        const countries = ["US", "CO", "SO", "UG", "RW", "VE", "EC", "CD", "JO", "SY", "PK", "ZA", "CA", "KE"];
        polygonTemplate.adapter.add("tooltipText", (text, target) => {
            if (countries.includes(target._dataItem._dataContext.id)) {
                return text;
            }
            return "";
        });

        // Add KujaKuja countries
        polygonSeries.data = [{
            id: "US",
            name: "United States",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "CO",
            name: "Colombia",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "ZA",
            name: "South Africa",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "CA",
            name: "Canada",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "KE",
            name: "Kenya",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "SO",
            name: "Somalia",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "UG",
            name: "Uganda",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "RW",
            name: "Rwanda",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "VE",
            name: "Venezuela",
            value: 1,
            fill: am4core.color("#535E65"),
        }, {
            id: "EC",
            name: "Ecuador",
            value: 1,
            fill: am4core.color("#FFC300"),
        }, {
            id: "CD",
            name: "Democratic Republic of the Congo",
            value: 1,
            fill: am4core.color("#535E65"),
        }, {
            id: "JO",
            name: "Jordan",
            value: 1,
            fill: am4core.color("#535E65"),
        }, {
            id: "SY",
            name: "Syria",
            value: 1,
            fill: am4core.color("#535E65"),
        }, {
            id: "PK",
            name: "Pakistan",
            value: 1,
            fill: am4core.color("#535E65"),
        }];

        // Bind "fill" property to "fill" key in data
        polygonTemplate.propertyFields.fill = "fill";
    }

    render() {
        const { globalTotal } = this.state;

        return (
            <div className="about">

                <div className="title-background-parent">
                    <div className="title-background-shape">
                        <div className="title-background-image" style={{ backgroundImage: `url(${require("../../img/about_bg_image.jpg")})` }}>
                            <div className="title-background-overlay" />
                        </div>
                    </div>
                </div>

                <Container className="title-parent">
                    <Row className="title-parent-row">
                        <Col className="col-sm-1 left-right-grid-column" />
                        <Col className="col-sm-10">
                            <h3 className="title">{i18n.t("about.title")}</h3>
                        </Col>
                        <Col className="col-sm-1 left-right-grid-column" />
                    </Row>
                    <Row className="title-parent-row">
                        <Col className="col-sm-1 left-right-grid-column" />
                        <Col className="col-sm-10">
                            <p className="sub-title">
                                {i18n.t("about.subTitle")}
                            </p>
                        </Col>
                        <Col className="col-sm-1 left-right-grid-column" />
                    </Row>
                </Container>

                <Container className="action-cards-container">
                    <Row>
                        <Col className="col-sm-12 col-md-6 action-cards-parent">
                            <ActionCard
                                title="1000s"
                                subTitle={i18n.t("about.actionsTakenCard.subTitle")}
                                description={i18n.t("about.actionsTakenCard.description")}
                            />
                        </Col>
                        <Col className="col-sm-12 col-md-6 action-cards-parent">
                            <ActionCard
                                countUp
                                title={globalTotal}
                                subTitle={i18n.t("about.customerscard.subTitle")}
                                description={i18n.t("about.customerscard.description")}
                            />
                        </Col>
                    </Row>
                    <Row className="about-map-parent">
                        <Col className="col-sm-12 about-map">
                            <h3>{i18n.t("about.map.title")}</h3>
                            <div className="blurb-parent">
                                <p className="blurb">
                                    {i18n.t("about.map.description")}
                                </p>
                                <ul>
                                    <li>
                                        <img
                                            alt=""
                                            className="ellipse"
                                            src={require("../../img/about_ellipse_yellow.svg")}
                                        />
                                        &nbsp;&nbsp;
                                        {i18n.t("about.map.currentPosition")}
                                    </li>
                                    <li>
                                        <img alt="" className="ellipse" src={require("../../img/about_ellipse_grey.svg")} />
                                        &nbsp;&nbsp;
                                        {i18n.t("about.map.comingSoon")}
                                    </li>
                                </ul>
                            </div>
                            <div id="kujakuja-map" />
                        </Col>

                    </Row>

                </Container>

                <div className="wide-parent">
                    <Container>
                        <Row>
                            <Col className="col-sm-12 teams">
                                <h3>{i18n.t("about.teams.title")}</h3>
                                <p>
                                    {i18n.t("about.teams.description")}
                                </p>

                                <div className="video-embed">
                                    <iframe
                                        title="Vimeo"
                                        src="https://player.vimeo.com/video/240502942?byline=0&portrait=0"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen"
                                        allowFullScreen
                                    />
                                    <script src="https://player.vimeo.com/api/player.js" />
                                </div>
                            </Col>
                        </Row>
                        <LearnMore />
                    </Container>
                </div>
            </div>
        );
    }
}
