import React from "react";
import { Col, Row } from "reactstrap";
import i18n from "i18next";

import "./raise.your.voice.scss";

const RaiseYourVoice = () => (
    <Row className="raise-voice-parent">
        <Col className="col-sm-12">
            <a href = "https://Raiseyourvoice.kujakuja.com">
                <h1 className="raise-voice">{i18n.t("landing.RaiseYourVoice")}</h1>
            </a>
        </Col>
    </Row>
);

export default RaiseYourVoice;
