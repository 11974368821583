export default class AppConfig {

    static get FIREBASE_CONFIG() {
        return {
            apiKey: "AIzaSyA4YxgrCH0z6YnOe8NB9LSNuOeppFV7j-E",
            authDomain: "kujakuja-prod.firebaseapp.com",
            databaseURL: "https://kujakuja-prod.firebaseio.com",
            projectId: "kujakuja-prod",
            storageBucket: "kujakuja-prod.appspot.com",
            messagingSenderId: "96267423825",
            appId: "1:96267423825:web:29aee9ecbab8e4cb"
        };
    }

}
