import React from "react";
import "./partner.card.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";

const PartnerCard = ({ item }) => {
    return (
        <div className="partner-card">
            <div className="partner-details" style={{ backgroundColor: item.bgColor }}>
                <img className={item.getInTouchUrl ? "logo-placeholder" : "logo"} src={item.logo} alt="" />
                <p className={item.getInTouchUrl ? "name-placeholder" : "name"}>{item.name}</p>
            </div>
            <a
                style={{ display: !item.publicDataUrl ? "none" : "inline-block" }}
                target="_blank"
                rel="noopener noreferrer"
                href={item.publicDataUrl}
                className="public-data right-arrow"
            >
                {i18n.t("partners.publicData")}
                {" "}
                <FontAwesomeIcon icon="arrow-right" />
            </a>
            <a
                style={{ display: !item.adminLoginUrl ? "none" : "inline-block", width: "50%" }}
                target="_blank"
                rel="noopener noreferrer"
                href={item.adminLoginUrl}
                className="admin-login right-arrow"
            >
                {i18n.t("partners.adminLogin")}
                {" "}
                <FontAwesomeIcon icon="arrow-right" />
            </a>
            <a
                style={{ display: !item.getInTouchUrl ? "none" : "inline-block" }}
                rel="noopener noreferrer"
                href={item.getInTouchUrl}
                className="get-in-touch right-arrow"
            >
                {i18n.t("partners.getInTouch")}
                {" "}
                <FontAwesomeIcon icon="arrow-right" />
            </a>
            <span
                style={{ display: (item.publicDataUrl || item.adminLoginUrl || item.getInTouchUrl) ? "none" : "inline-block" }}
                className="coming-soon"
            >
                {i18n.t("partners.comingSoon")}
            </span>
        </div>
    );
};
export default PartnerCard;
