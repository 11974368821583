import React from "react";
import './contact.scss';

export default class FooterActionCard extends React.Component {

    render() {
        return <div className='footer-action-card'>
            <div className='action-card-content'>
                <h2>{this.props.title}</h2>
                <p>{this.props.description}</p>
            </div>
            <div className='action-card-footer'>
                {this.props.footerLink}
            </div>
        </div>
    }

}
