import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import i18n from "i18next";

import "./learn.more.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LearnMore = () => (
    <Row className="learn-more-parent">
        <Col className="col-sm-12">
            <h1 className="learn-more">{i18n.t("landing.learnMoreTitle")}</h1>
            <Link to="/work-with-us" className="right-arrow">
                {i18n.t("landing.learnMoreLink")}
                {" "}
                <FontAwesomeIcon icon="arrow-right" />
            </Link>
        </Col>
    </Row>
);

export default LearnMore;
