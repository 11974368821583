import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import i18n from "i18next";

import "./footer.scss";

const Footer = () => (
    <div className="footer">
        <Container>
            <Row>
                <Col className="col-md-2">
                    <a rel="noopener noreferrer" href="/"><img alt={i18n.t("footer.kujakujaLogoAlt")} src={require("../../img/global_logo_light.svg")} /></a>
                </Col>
                <Col className="col-md-2">
                    <h4 className="sub-header">{i18n.t("footer.products")}</h4>
                    <a href="https://alight.kujakuja.com/datamap" className="footer-item">{i18n.t("footer.dataMap")}</a>
                    <a href="https://alight.kujakuja.com/ideafeed" className="footer-item">{i18n.t("footer.ideaFeed")}</a>
                    <a href="https://alight.kujakuja.com/snapshots" className="footer-item">{i18n.t("footer.snapshotCards")}</a>
                </Col>
                <Col className="col-md-2">
                    <h4 className="sub-header">{i18n.t("footer.community")}</h4>
                    <a href="https://www.instagram.com/kujakujaglobal/" target="_blank" rel="noopener noreferrer" className="footer-item">Instagram</a>
                    <a href="https://www.facebook.com/KujaKujaGlobal" target="_blank" rel="noopener noreferrer" className="footer-item">Facebook</a>
                    <a href="https://twitter.com/kujakujaglobal" target="_blank" rel="noopener noreferrer" className="footer-item">Twitter</a>
                    <a href="https://medium.com/@KujaKujaGlobal" target="_blank" rel="noopener noreferrer" className="footer-item">Medium</a>
                </Col>
                <Col className="col-md-2">
                    <h4 className="sub-header">{i18n.t("footer.company")}</h4>
                    <Link to="/about" className="footer-item">{i18n.t("footer.aboutUs")}</Link>
                    <Link to="/work-with-us" className="footer-item">{i18n.t("footer.workWithUs")}</Link>
                    <Link to="/contact" className="footer-item">{i18n.t("footer.contact")}</Link>
                    <Link to="/partners" className="footer-item">{i18n.t("footer.partnerLogin")}</Link>
                    <Link to="/terms-and-privacy" className="footer-item">{i18n.t("footer.terms")}</Link>
                </Col>
                <Col className="col-md-2" />
                <Col className="col-md-2" />
            </Row>
        </Container>
    </div>
);
export default Footer;
