import React from "react";
import './about.scss';
import CountUp from 'react-countup';

export default class ActionCard extends React.Component {

    constructor(){
        super();
        this.state = {
            start: 0
        }
    }

    resetStart(newGlobalTotal){
        this.setState({
            start: newGlobalTotal
        });
    }

    render() {
        const title = this.props.countUp ? <h2>
                <CountUp start={this.state.start}
                         preserveValue={true}
                         redraw={true}
                         separator=" "
                         duration={2.75}
                         onEnd={() => this.resetStart(this.props.title)}
                         end={this.props.title}></CountUp>
            </h2>
            : <h2>{this.props.title}</h2>;

        return <div className='action-card'>{title}<h3>{this.props.subTitle}</h3><p>{this.props.description}</p></div>
    }

}
