import React from "react";
import "./goldstar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";

const GoldStarStoryCard = ({ story }) => (
    <div className="gold-star-card">
        <img alt="" className="story" src={story.img} />
        <p className="description">{story.description}</p>
        <a target="_blank" rel="noopener noreferrer" href={story.url} className="full-story right-arrow">
            {i18n.t("landing.fullStory")}
            <FontAwesomeIcon icon="arrow-right" />
        </a>
    </div>
);

export default GoldStarStoryCard;
