import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Intercom from "react-intercom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowRight, faRedo, faCalendarAlt, faChartArea, faTasks, faShareSquare, faSearch, faAward,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";

import "./style/app.scss";
import Landing from "./components/landing/Landing";
import About from "./components/about/About";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import WorkWithUs from "./components/work_with_us/WorkWithUs";
import Contact from "./components/contact/Contact";
import PrivacyPolicy from "./components/privacy_policy/PrivacyPolicy";
import ScrollToTop from "./components/scroll_to_top/ScrollToTop";
import NoPage from "./components/no_page/NoPage";
import Partners from "./components/partners/Partners";
import withTracker from "./components/withTracker";

library.add(faArrowRight, faRedo, faCalendarAlt, faChartArea, faTasks, faShareSquare, faSearch, faAward);

class App extends Component {
    constructor() {
        super();
        this.state = {
            language: i18n.language,
        };
    }

    componentDidMount() {
        const { language } = this.state;
        const newUrl = this.generateUrlWhenLanguageChanges(language);
        const { location: { href } } = window;
        if (href.indexOf("en") === -1 && href.indexOf("es") === -1 && href.indexOf("sw") === -1 && href.indexOf("ru") === -1 && href.indexOf("uk") === -1) {
            window.location.assign(newUrl);
        }
    }

    changeLanguage = (event) => {
        const { value } = event.target;
        this.setState({ language: value });
        const newUrl = this.generateUrlWhenLanguageChanges(value);
        window.location.assign(newUrl);
        i18n.changeLanguage(value);
    }

    generateUrlWhenLanguageChanges = (lang) => {
        const urlPath = window.location.pathname;
        const pathsArray = urlPath.split("/");
        // eslint-disable-next-line no-unused-vars
        const [firstIndex, secondIndex, ...requiredPathNames] = pathsArray;
        const newUrl = `/${lang}/${requiredPathNames}`;
        return newUrl;
    }

    render() {
        const { language } = this.state;

        return (
            <Router basename={`/${language}`}>
                <Intercom appID="e6j882sk" />
                <Header changeLanguage={this.changeLanguage} selectedLanguage={language} />
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" component={withTracker(Landing)} />
                        <Route path="/about" component={withTracker(About)} />
                        <Route path="/work-with-us" component={withTracker(WorkWithUs)} />
                        <Route path="/contact" component={withTracker(Contact)} />
                        <Route path="/partners" component={withTracker(Partners)} />
                        <Route path="/terms-and-privacy" component={withTracker(PrivacyPolicy)} />
                        <Route component={NoPage} />
                    </Switch>
                </ScrollToTop>
                <Footer />
            </Router>
        );
    }
}

export default App;
