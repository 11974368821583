import React from "react";
import {
    Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from 'i18next';

import IconActionCard from "./IconActionCard";
import "./work.with.us.scss";

export default class WorkWithUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: "0",
            successStories: [
                {
                    title: `${i18n.t("workWithUs.successStoryIssueTracking.title")}`,
                    alt: `${i18n.t("workWithUs.successStoryIssueTracking.alt")}`,
                    img: require("../../img/work-with-us_analysis.png"),
                    heading: `${i18n.t("workWithUs.successStoryIssueTracking.heading")}`,
                    how: `${i18n.t("workWithUs.successStoryIssueTracking.how")}`,
                    impact: `${i18n.t("workWithUs.successStoryIssueTracking.impact")}`,
                },
                {
                    title: `${i18n.t("workWithUs.successStoryDailyFeedback.heading")}`,
                    alt: `${i18n.t("workWithUs.successStoryDailyFeedback.alt")}`,
                    img: require("../../img/work-with-us_health.jpg"),
                    heading: `${i18n.t("workWithUs.successStoryDailyFeedback.heading")}`,
                    how: `${i18n.t("workWithUs.successStoryDailyFeedback.how")}`,
                    impact: `${i18n.t("workWithUs.successStoryDailyFeedback.impact")}`,
                },
                {
                    title: `${i18n.t("workWithUs.successStoryStrategicChange.heading")}`,
                    alt: `${i18n.t("workWithUs.successStoryStrategicChange.alt")}`,
                    img: require("../../img/work-with-us_water.jpg"),
                    heading: `${i18n.t("workWithUs.successStoryStrategicChange.heading")}`,
                    how: `${i18n.t("workWithUs.successStoryStrategicChange.how")}`,
                    impact: `${i18n.t("workWithUs.successStoryStrategicChange.impact")}`,
                },
            ],
        };

        this.setActiveTab = this.setActiveTab.bind(this);
    }

    componentDidMount() {
        document.title = `${i18n.t("workWithUs.title")}`;
    }

    setActiveTab(tabId) {
        if (this.state.activeTab !== tabId) {
            this.setState({
                activeTab: tabId,
            });
        }
    }

    render() {
        return (
            <div className="work-with-us">

                <div className="title-background-parent">
                    <div className="title-background-shape" />
                </div>

                <Container className="title-parent">
                    <Row>
                        <Col className="col-sm-12">
                            <h3 className="title">{i18n.t("workWithUs.title")}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-12">
                            <p className="sub-title">
                                {i18n.t("workWithUs.subTitle")}
                            </p>
                        </Col>
                    </Row>
                </Container>

                <Container id="success-stories-desktop">
                    <Row>
                        <Col className="col-sm-12">
                            <Nav tabs>
                                {
                                    this.state.successStories.map((story, index) => (
                                        <NavItem key={index}>
                                            <NavLink className={this.state.activeTab === index.toString() ? "active" : ""}
                                                onClick={() => this.setActiveTab(index.toString())}
                                            >
                                                {story.title}
                                            </NavLink>
                                        </NavItem>
                                    ))
                                }
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                {
                                    this.state.successStories.map((story, index) => (
                                        <TabPane tabId={index.toString()} key={index}>
                                            <div className="image-container">
                                                <img alt={story.alt} src={story.img} />
                                                <div className="overlay" />
                                            </div>
                                            <h4>{story.heading}</h4>
                                            <p>
                                                <strong>
                                                    {i18n.t("workWithUs.how")}
                                                </strong>
                                                <br />
                                                {story.how}
                                            </p>
                                            <p>
                                                <strong>
                                                    {i18n.t("workWithUs.impact")}
                                                </strong>
                                                <br />
                                                {story.impact}
                                            </p>
                                            <Link className="cta-get-in-touch right-arrow" to="/contact">
                                                {i18n.t("workWithUs.getInTouch")}
                                                {" "}
                                                <FontAwesomeIcon icon="arrow-right" />
                                            </Link>
                                        </TabPane>
                                    ))
                                }
                            </TabContent>
                        </Col>
                    </Row>

                </Container>

                <Container id="success-stories-mobile">
                    {
                        this.state.successStories.map((story, index) => (
                            <Row key={index}>
                                <Col className="col-sm-12 success-stories-container">
                                    <h3>{story.title}</h3>
                                    <img alt={story.alt} src={story.img} />
                                    <h4>{story.heading}</h4>
                                    <p>
                                        <strong>{i18n.t("workWithUs.how")}</strong>
                                        <br />
                                        {story.how}
                                    </p>
                                    <p>
                                        <strong>{i18n.t("workWithUs.impact")}</strong>
                                        <br />
                                        {story.impact}
                                    </p>
                                    <Link className="cta-get-in-touch right-arrow" to="/contact">
                                        {i18n.t("workWithUs.getInTouch")}
                                        {" "}
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </Link>
                                </Col>
                            </Row>
                        ))
                    }
                </Container>

                <div className="the-kk-process">
                    <Container>
                        <Row>
                            <Col className="col-sm-12">
                                <h3>{i18n.t("workWithUs.kkProcess.title")}</h3>
                                <p className="subtext">
                                    {i18n.t("workWithUs.kkProcess.subText")}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-sm-12 col-lg-4 col-md-2 action-cards-parent">
                                <IconActionCard
                                    icon={<FontAwesomeIcon icon="calendar-alt" />}
                                    title={i18n.t("workWithUs.kkProcess.actionCard.getOnThePulse.title")}
                                    description={i18n.t("workWithUs.kkProcess.actionCard.getOnThePulse.description")}
                                />
                            </Col>
                            <Col className="col-sm-12 col-lg-4 col-md-2 action-cards-parent">
                                <IconActionCard
                                    icon={<FontAwesomeIcon icon="chart-area" />}
                                    title={i18n.t("workWithUs.kkProcess.actionCard.learnThePatterns.title")}
                                    description={i18n.t("workWithUs.kkProcess.actionCard.learnThePatterns.description")}
                                />
                            </Col>
                            <Col className="col-sm-12 col-lg-4 col-md-2 action-cards-parent">
                                <IconActionCard
                                    icon={<FontAwesomeIcon icon="tasks" />}
                                    title={i18n.t("workWithUs.kkProcess.actionCard.makeSimpleChanges.title")}
                                    description={i18n.t("workWithUs.kkProcess.actionCard.makeSimpleChanges.description")}
                                />
                            </Col>
                            <Col className="col-sm-12 col-lg-4 col-md-2 action-cards-parent">
                                <IconActionCard
                                    icon={<FontAwesomeIcon icon="share-square" />}
                                    title={i18n.t("workWithUs.kkProcess.actionCard.embraceTransparency.title")}
                                    description={i18n.t("workWithUs.kkProcess.actionCard.embraceTransparency.description")}
                                />
                            </Col>
                            <Col className="col-sm-12 col-lg-4 col-md-2 action-cards-parent">
                                <IconActionCard
                                    icon={<FontAwesomeIcon icon="search" />}
                                    title={i18n.t("workWithUs.kkProcess.actionCard.uncoverDeeperInsight.title")}
                                    description={i18n.t("workWithUs.kkProcess.actionCard.uncoverDeeperInsight.description")}
                                />
                            </Col>
                            <Col className="col-sm-12 col-lg-4 col-md-2 action-cards-parent">
                                <IconActionCard
                                    icon={<FontAwesomeIcon icon="award" />}
                                    title={i18n.t("workWithUs.kkProcess.actionCard.investInAction.title")}
                                    description={i18n.t("workWithUs.kkProcess.actionCard.investInAction.description")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-sm-12">
                                <div id="repeat-arrow-parent">
                                    <img id="repeat-arrow" alt="" src={require("../../img/repeat-line.png")} />
                                    <div id="repeat">
                                        <FontAwesomeIcon id="icon" icon="redo" />
                                        {" "}
                                        {i18n.t("workWithUs.kkProcess.actionCard.repeat")}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>

            </div>
        );
    }
}
