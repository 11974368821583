import React from "react";
import './work.with.us.scss';

export default class IconActionCard extends React.Component {

    render() {
        return <div className='icon-action-card'>
            <div className='action-card-icon'>
                {this.props.icon}
            </div>
            <h2>{this.props.title}</h2>
            <p>{this.props.description}</p>
        </div>
    }

}
