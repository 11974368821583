import React from "react";
import "./partners.scss";
import { Col, Container, Row } from "reactstrap";
import i18n from "i18next";
import PartnerCard from "./PartnerCard";

export default class Partners extends React.Component {
    constructor() {
        super();

        this.state = {
            partners: [
                {
                    name: "Alight",
                    logo: require("../../img/partners/alight-logo.svg"),
                    bgColor: "#1A24A3",
                    publicDataUrl: "https://alight.kujakuja.com",
                    adminLoginUrl: "https://admin.alight.kujakuja.com/",
                },
                {
                    name: "NRC",
                    logo: require("../../img/partners/nrc-logo.png"),
                    bgColor: "#F87303",
                    publicDataUrl: "https://nrc.kujakuja.com",
                    adminLoginUrl: "https://admin.nrc.kujakuja.com/",
                },
                {
                    name: "CRC",
                    logo: require("../../img/partners/crc-logo.png"),
                    bgColor: "#FE0202",
                    publicDataUrl: "https://crc.kujakuja.com",
                    adminLoginUrl: "https://admin.crc.kujakuja.com/",
                },
                {
                    name: "ADRA",
                    logo: require("../../img/partners/adra-logo.png"),
                    bgColor: "#00765D",
                    publicDataUrl: "https://adra.kujakuja.com",
                    adminLoginUrl: "https://admin.adra.kujakuja.com/",
                },
                {
                    name: "USAID/OFDA",
                    logo: require("../../img/partners/USAID-logo.svg"),
                    bgColor: "#C2113A",
                    publicDataUrl: "https://ofda.kujakuja.com",
                    adminLoginUrl: "https://admin.ofda.kujakuja.com/",
                },
                {
                    name: "CUA",
                    logo: require("../../img/partners/cua-logo.png"),
                    bgColor: "#f07f2c",
                    publicDataUrl: "https://cua.kujakuja.com",
                    adminLoginUrl: "https://admin.cua.kujakuja.com/",
                },
                {
                    name: i18n.t("partners.partnerCardYourOrganization"),
                    logo: require("../../img/partners/_.svg"),
                    bgColor: "#00BA54",
                    getInTouchUrl: "/contact",
                },
            ],
        };
    }

    componentDidMount() {
        document.title = `${i18n.t("partners.pageTitle")}`;
    }

    render() {
        const { partners } = this.state;
        return (
            <div className="partners">

                <div className="title-background-parent">
                    <div className="title-background-shape" />
                </div>

                <Container className="title-parent">
                    <Row>
                        <Col className="col-sm-12">
                            <h3 className="title">{i18n.t("partners.ourPartners")}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-sm-12">
                            <p className="sub-title">
                                {i18n.t("partners.partnersSubTitle")}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        {partners.map((item, index) => {
                            return (
                                <Col className="col-sm-12 col-md-4 cards-parent" key={index}>
                                    <PartnerCard item={item} />
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </div>
        );
    }
}
