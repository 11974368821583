import React from "react";
import { Col, Container, Row } from "reactstrap";
import i18n from "i18next";
import "./landing.scss";
import { Fade } from "react-slideshow-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoldStarStoryCard from "./GoldStarStoryCard";
import LearnMore from "../common/LearnMore";
import RaiseYourVoice from "../common/RaiseYourVoice";

const sliderProperties = {
    duration: 5000,
    transitionDuration: 750,
    infinite: true,
    indicators: true,
    arrows: false,
};



export default class Landing extends React.Component {
    constructor() {
        super();
        this.state = {
            sliderImages: [
                {
                    img: require("../../img/landing_slider_image_1.jpg"),
                    description: i18n.t("landing.sliderImage1"),
                },
                {
                    img: require("../../img/landing_slider_image_2.jpg"),
                    description: i18n.t("landing.sliderImage2"),
                },
                {
                    img: require("../../img/landing_slider_image_3.jpg"),
                    description: i18n.t("landing.sliderImage3"),
                },
            ],
            goldStarStories: [
                {
                    img: require("../../img/goldstar1.png"),
                    description: i18n.t("landing.goldStarFirstStoryDescription"),
                    url: "https://kujakujagoldstars.com/home/2018/7/5/ixr8nura074n5n64m4bp65akt7dcee-eapgr-hya4n-hpk2g-gx5cy-f8465-2w6hy-8t9kg",
                },
                {
                    img: require("../../img/goldstar2.png"),
                    description: i18n.t("landing.goldStarSecondStoryDescription"),
                    url: "https://kujakujagoldstars.com/home/2018/7/5/ixr8nura074n5n64m4bp65akt7dcee-eapgr-hya4n-82426-h32lw-9y6b5-99j8h-4p8py-7xg5g-l6ek8-zwg4a-wkcfn-6cc7w-64kr4-j74rh-x7mbj-c6c9z-fwmly-erspl-9afxn-c3d8l-r6t3z-lbp7w-rp6wp-es77c-zarkg-d7l8t",
                },
                {
                    img: require("../../img/goldstar3.png"),
                    description: i18n.t("landing.goldStarThirdStoryDescription"),
                    url: "https://kujakujagoldstars.com/home/2018/7/5/ixr8nura074n5n64m4bp65akt7dcee-eapgr-hya4n-82426-h32lw-9y6b5",
                },
            ],
        };
    }

    componentDidMount() {
        document.title = "Kuja Kuja";
    }


    render() {
        return (
            <div className="landing">
                <div className="title-background-parent">
                    <div className="title-background-shape">
                        <div className="title-background-image" style={{ backgroundImage: `url(${require("../../img/landing_bg_image.jpg")})` }}>
                            <div className="title-background-overlay" />
                        </div>
                    </div>
                </div>
                <RaiseYourVoice />
                <Container className="title-parent">
                    <Row>
                        <Col className="col-sm-1 left-right-grid-column" />
                        <Col className="col-sm-10">
                            <h3 className="title">{i18n.t("landing.title") }</h3>
                        </Col>
                        <Col className="col-sm-1 left-right-grid-column" />
                    </Row>
                    <Row>
                        <Col className="col-sm-1 left-right-grid-column" />
                        <Col className="col-sm-10">
                            <p className="sub-title">
                                {i18n.t("landing.titleDescription")}
                            </p>
                        </Col>
                        <Col className="col-sm-1 left-right-grid-column" />
                    </Row>
                    <Row>
                        <Col className="col-sm-1" />
                        <Col className="col-sm-10">
                            <Fade
                                style={{
                                    padding: "10px 0",
                                    width: "100%",
                                    textAlign: "center",
                                    margin: "auto",
                                }}
                                {...sliderProperties}
                            >
                                {this.state.sliderImages.map((item, index) => (
                                    <div className="each-slide" key={index}>
                                        <div className="slider" style={{ backgroundImage: `url(${item.img})` }}>
                                            <span>{item.description}</span>
                                        </div>
                                    </div>
                                ))}
                            </Fade>
                        </Col>
                        <Col className="col-sm-1" />
                    </Row>
                </Container>
                <Container className="data-map-parent">
                    <Row className="sub-section">
                        <Col className="col-sm-12 col-md-4">
                            <h4>{i18n.t("landing.dataMapTitle")}</h4>
                            <p className="subtitle-description">
                                {i18n.t("landing.dataMapContent")}
                            </p>
                            <p>
                                <a href="https://alight.kujakuja.com/datamap" target="_blank" rel="noopener noreferrer" className="right-arrow">
                                    {i18n.t("landing.dataMapLink")}
                                    {" "}
                                    <FontAwesomeIcon icon="arrow-right" />
                                </a>
                            </p>
                        </Col>
                        <Col className="col-sm-12 col-md-8">
                            <img
                                alt={i18n.t("landing.dataMapScreenshotAlt")}
                                className="summary"
                                src={require("../../img/desktop_map_ui.png")}
                            />
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row className="sub-section">
                        <Col className="col-sm-12 col-md-8">
                            <Row>
                                <Col id="vegetables-grand-parent" className="col-sm-1">
                                    <div id="vegetables-parent">
                                        <img id="vegetables-image" alt="" src={require("../../img/search_term_vegetables.svg")} />
                                        <img id="vegetables-arrow" alt="" src={require("../../img/arrow5.svg")} />

                                    </div>
                                </Col>
                                <Col className="col-sm-11" style={{ textAlign: "center" }}>
                                    <img
                                        alt={i18n.t("landing.ideaFeedScreenshotAlt")}
                                        className="summary"
                                        src={require("../../img/feed_ui.png")}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-sm-12 col-md-4">
                            <h4>{i18n.t("landing.ideaFeedTitle")}</h4>
                            <p className="subtitle-description">
                                {i18n.t("landing.ideaFeedContent")}
                            </p>
                            <p>
                                <a href="https://alight.kujakuja.com/ideafeed" target="_blank" rel="noopener noreferrer" className="right-arrow">
                                    {i18n.t("landing.ideaFeedLink")}
                                    {" "}
                                    <FontAwesomeIcon icon="arrow-right" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <div className="snapshots-parent">
                    <div className="snapshots-background-shape" />
                    <Container>
                        <Row className="sub-section">
                            <Col className="col-sm-12 col-md-4">
                                <h4>{i18n.t("landing.snapshotTitle")}</h4>
                                <p className="subtitle-description">
                                    {i18n.t("landing.snapshotContent")}
                                </p>
                                <p>
                                    <a href="https://alight.kujakuja.com/snapshots" target="_blank" rel="noopener noreferrer" className="right-arrow">
                                        {i18n.t("landing.snapshotLink")}
                                        {" "}
                                        <FontAwesomeIcon icon="arrow-right" />
                                    </a>
                                </p>
                            </Col>
                            <Col id="snapshot-card-1" className="col-sm-12 col-md-4">
                                <Row>
                                    <Col className="col-sm-3" />
                                    <Col className="col-sm-9" id="snapshot-card-1-parent-col-1">
                                        <div id="snapshot-card-1-parent-div-1">
                                            <img alt="" src={require("../../img/gmail.png")} id="snapshot-card-1-img-gmail" />
                                            <img alt="" src={require("../../img/arrow1.svg")} id="snapshot-card-1-img-arrow-1" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-sm-3 snapshot-image-parent">
                                        <div id="snapshot-card-1-parent-div-2">
                                            <img alt="" src={require("../../img/arrow2.svg")} id="snapshot-card-1-img-arrow-2" />
                                            <img
                                                alt=""
                                                src={require("../../img/telegram.png")}
                                                id="snapshot-card-1-img-telegram"
                                            />
                                        </div>
                                    </Col>
                                    <Col className="col-sm-9" id="snapshot-card-1-parent-col-2">
                                        <img
                                            alt={i18n.t("landing.snapshotCardScreenshotAlt")}
                                            src={require("../../img/snapshot_card_1.png")}
                                            id="snapshot-card-1-img-snapshot"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col id="snapshot-card-2" className="col-sm-12 col-md-4">
                                <Row>
                                    <Col className="col-sm-9" />
                                    <Col className="col-sm-3">
                                        <div id="snapshot-card-2-parent-div-1">
                                            <img alt="" src={require("../../img/messenger.png")} id="snapshot-card-2-img-messenger" />
                                            <img alt="" src={require("../../img/arrow3.svg")} id="snapshot-card-2-img-arrow-1" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-sm-9" id="snapshot-card-2-parent-col-2">
                                        <img
                                            alt={i18n.t("landing.snapshotCardScreenshotAlt")}
                                            src={require("../../img/snapshot_card_2.png")}
                                            id="snapshot-card-2-img-snapshot"
                                        />
                                    </Col>
                                    <Col className="col-sm-3 snapshot-image-parent">
                                        <div id="snapshot-card-2-parent-div-2">
                                            <img alt="" src={require("../../img/arrow4.svg")} id="snapshot-card-2-img-arrow-2" />
                                            <img alt="" src={require("../../img/whatsapp.png")} id="snapshot-card-2-img-whatsapp" />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col id="snapshot-card-3" className="col-sm-12 col-md-4">
                                <img
                                    className="summary-portrait"
                                    alt={i18n.t("landing.snapshotCardScreenShotAlt")}
                                    src={require("../../img/snapshot_card_1.png")}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <Row className="goldstars-parent">
                        <Col className="col-sm-12 title-parent">
                            <h4>{i18n.t("landing.goldStarTitle")}</h4>
                            <p className="explained">
                                {i18n.t("landing.goldStarContent")}
                            </p>
                        </Col>
                        <Col className="col-sm-12 cards-parent">
                            {this.state.goldStarStories.map((item, index) => <GoldStarStoryCard story={item} key={index} />)}
                        </Col>
                    </Row>
                    <LearnMore />
                    <Row>
                        <Col className="col-sm-12">
                            <p className="recognised-by">{i18n.t("landing.recognisedBy")}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="sponsors-parent col-sm-12">
                            <a
                                className="sponsor"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.fastcompany.com/40575160/at-these-camps-refugees-can-give-real-time-customer-feedback"
                            >
                                <img alt={i18n.t("landing.fastCompanyLogoAlt")} src={require("../../img/fastco.svg")} />

                            </a>
                            <a
                                className="sponsor"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://designawards.core77.com/Service-Design/74284/Kuja-Kuja"
                            >
                                <img
                                    alt={i18n.t("landing.core77LogoAlt")}
                                    src={require("../../img/core77.svg")}
                                />

                            </a>
                            <a
                                className="sponsor"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="http://www.maya-pedal.org/news/bill-melinda-gates-foundation-discovery-center-presents-design-with-the-90/"
                            >
                                <img
                                    alt={i18n.t("landing.gatesFoundationLogoAlt")}
                                    src={require("../../img/gates.svg")}
                                />

                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
